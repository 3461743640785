<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="5" class="text-left">
          <h3 class="mb-5">Crea tu nuevo <span class="primary--text">nuevo tipo de local</span> </h3>
          <p class="text-left">
            Un Tipo de Local es una forma de agrupar
            varios locales con características comunes.
            Por ejemplo “Bares”, “Restaurantes” o
            “Pizzerías”.
          </p>
          <p>
            Los locales de un mismo tipo pueden
            compartir : Productos, Cartas y Menús y
            Modificadores de Productos.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <v-form>
            <v-text-field :error-messages="nameErrors"
                          v-model="name"
                          placeholder=" "
                          label="Nombre del nuevo tipo de local" required>
            </v-text-field>
            <v-select
                v-model="currency"
                :items="currencyItems"
                label="Moneda"
                data-vv-name="select"
                required
            ></v-select>
            <v-select
                v-model="business"
                :items="businessItems"
                label="Negocio"
                data-vv-name="select"
                :error-messages="businessTypeErrors"
                required
            ></v-select>

          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-6">
      <v-row>
        <v-col cols="12" md="3">
          <v-btn @click="$emit('back')">Anterior</v-btn>
        </v-col>
        <v-col cols="12" md="3" offset="6">
          <v-btn color="primary" @click="submit">Siguiente</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

export default {
  name: "LocalType",
  mixins: [validationMixin],
  data: () => ({
    name: '',
    currency: 'euro',
    currencyItems: [
      {text: "Euro", value: "euro"},
      {text: "Dollar", value: "dollar"},
      {text: "Libra", value: "pound"}
    ],
    businessItems: [],
    business: null
  }),
  props: {
    value: {
      required: true
    }
  },
  validations: {
    name: {required},
  },
  async mounted() {
    this.name = this.value.business.type
    this.currency = this.value.business.currency
    this.businessItems = (await this.$http.get('v2/utils/get_business_types')).data
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Nombre obligatorio.')
      return errors
    },
    businessTypeErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Tipo de negocio obligatorio.')
      return errors
    },
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.value.business.type = this.name
        this.value.business.currency = this.currency
        this.value.business.business_type = this.business
        this.$emit("next")
        this.$emit("input", this.value)
      }
    },
  }
}
</script>
